import React, { useState, createContext } from "react"
export const ProfileContext = createContext()
export const ProfileContextProvider = ({ children }) => {
  const [profileData, setProfileData] = useState({
    // Variables here
    loaded: false
  })
  return (
    <ProfileContext.Provider value={[profileData, setProfileData]}>
      {children}
    </ProfileContext.Provider>
  )
}
