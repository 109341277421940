export default function Button({
  children,
  Icon,
  className,
  clickHandler,
  ...props
}) {
  return (
    <button
      type="button"
      className={`inline-flex items-center rounded-md text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 px-4 ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

// {text ? text : <Icon />}
