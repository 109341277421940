import { useEffect, useState } from "react"

import Select from "react-select"

export default function DropdownMultiple ({
  title = "",
  placeholder,
  options,
  classRoot,
  classBox,
  className,
  onChange,
  error,
  touched,
  disabled = false,
  value,
  ...props
}) {
  const [selectedPerson, setSelectedPerson] = useState([])

  const handleChange = selectedOption => {
    setSelectedPerson(selectedOption)
    onChange(selectedOption)
  }

    return (
      <Select
        isMulti
        value={selectedPerson}
        onChange={handleChange}
        options={options}
        placeholder="Select Template"
      />
    );
}
