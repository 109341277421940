import { useState } from "react"
// import AutoComplete from "react-google-autocomplete"
import { FaMapMarkerAlt } from "react-icons/fa"
import { IconContext } from "react-icons"
import { useFormik } from "formik"

import {
  Checkbox,
  Input,
  Dropdown,
  Tabs,
  Button,
  GoogleMap,
  Textarea
} from "../../common"
import {
  createCampaignStepFive,
  createCampaignStepFour,
  createCampaignStepOne,
  createCampaignStepThree,
  createCampaignStepTwo
} from "../../../helpers/validationSchema"

export const CampaignStructure = ({ submitData }) => {
  const formik = useFormik({
    initialValues: {
      number_of_campaign: "",
      number_of_adsets: "",
      number_of_ads: ""
    },
    validationSchema: createCampaignStepOne,
    onSubmit: submitData
  })
  return (
    <form onSubmit={formik.handleSubmit} className="py-5 border rounded-md">
      <div className="border-b-2 px-5 ">
        <p className="mb-2">Campaign Structure</p>
      </div>
      <div className="mx-5 my-5">
        <div className="grid grid-cols-2 gap-3">
          <Input
            placeholder="Number of Campaign"
            name="number_of_campaign"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.number_of_campaign}
            error={formik.errors.number_of_campaign}
            touched={formik.touched.number_of_campaign}
          />
          <Input
            placeholder="Number of Adsets"
            name="number_of_adsets"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.number_of_adsets}
            error={formik.errors.number_of_adsets}
            touched={formik.touched.number_of_adsets}
          />
        </div>
        <Input
          placeholder="Number of Ads"
          name="number_of_ads"
          type="number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.number_of_ads}
          error={formik.errors.number_of_ads}
          touched={formik.touched.number_of_ads}
        />
        {/* TODO: missing links for checbox */}
        <div className="flex items-start">
          <Checkbox
            title="Per Ad Set"
            name="budget_price"
            // onChange={() => formik.setFieldValue("budget_price", "Per_ad_set")}
            onBlur={formik.handleBlur}
            value={formik.values.budget_price}
            error={formik.errors.budget_price}
            touched={formik.touched.budget_price}
          />
          <div className="mx-2" />
          <Checkbox
            title="Per Campagin"
            name="budget_price"
            // onChange={() =>
            //   formik.setFieldValue("budget_price", "Per_campaigns")
            // }
            onBlur={formik.handleBlur}
            value={formik.values.budget_price}
            error={formik.errors.budget_price}
            touched={formik.touched.budget_price}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          type="submit"
          className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
        >
          Next
        </Button>
      </div>
    </form>
  )
}

export const CampaignData = ({ submitData }) => {
  const [showEndData, setShowEndDate] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: "",
      category: {},
      objective: {},
      budget_type: "",
      budget_criteria: {},
      budget_price: "",
      strategy_type: {},
      strategy_price: "",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: ""
    },
    validationSchema: createCampaignStepTwo,
    onSubmit: submitData
  })
  return (
    <form onSubmit={formik.handleSubmit} className="py-5 border rounded-md">
      <div className="border-b-2 px-5 ">
        <p className="mb-2">Campaign</p>
      </div>
      <div className="mx-5 my-5">
        <Input
          placeholder="Campaign Name"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          error={formik.errors.name}
          touched={formik.touched.name}
        />
        <p className="font-bold my-2">Special Ad Categories</p>
        <Dropdown
          placeholder="Categories"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
          name="category"
          onChange={value => formik.setFieldValue("category", value)}
          onBlur={formik.handleBlur}
          value={formik.values.category}
          error={formik.errors.category}
          touched={formik.touched.category}
        />
        <Dropdown
          placeholder="Campaign Objective"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
          name="objective"
          onChange={value => formik.setFieldValue("objective", value)}
          onBlur={formik.handleBlur}
          value={formik.values.objective}
          error={formik.errors.objective}
          touched={formik.touched.objective}
        />
        <p className="font-bold my-2">Budget and Scheduling</p>
        <p className="text-sm mb-1">Budget Type</p>
        <div className="flex items-start">
          <Checkbox
            title="CBO"
            name="budget_type"
            onChange={() => formik.setFieldValue("budget_type", "CBO")}
            onBlur={formik.handleBlur}
            value={formik.values.budget_type}
            error={formik.errors.budget_type}
            touched={formik.touched.budget_type}
          />
          <div className="mx-2" />
          <Checkbox
            title="ABO"
            name="budget_type"
            onChange={() => formik.setFieldValue("budget_type", "ABO")}
            onBlur={formik.handleBlur}
            value={formik.values.budget_type}
            error={formik.errors.budget_type}
            touched={formik.touched.budget_type}
          />
        </div>
        {formik.errors.budget_type && formik.touched.budget_type && (
          <p className="text text-red-500">{formik.errors.budget_type}</p>
        )}
        <p className="text-sm mt-3 mb-1">Campaign Budget</p>
        <div className="grid grid-cols-2 gap-3">
          <Dropdown
            placeholder="Daily Budget"
            classRoot="mt-1"
            options={[
              {
                label: "1",
                value: "1"
              },
              {
                label: "2",
                value: "2"
              }
            ]}
            name="budget_criteria"
            onChange={value => formik.setFieldValue("budget_criteria", value)}
            onBlur={formik.handleBlur}
            value={formik.values.budget_criteria}
            error={formik.errors.budget_criteria}
            touched={formik.touched.budget_criteria}
          />
          <Input
            placeholder="$ 24.00"
            className="mt-0"
            iconClasses={"absolute inset-y-0 right-0 flex pr-3 cursor-pointer"}
            Icon={() => (
              <div className="pointer-events-none absolute border-l pl-2 border-l-black inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  USD
                </span>
              </div>
            )}
            name="budget_price"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.budget_price}
            error={formik.errors.budget_price}
            touched={formik.touched.budget_price}
          />
        </div>
        <p className="text-sm mt-3 mb-1">Campaign Bid Strategies</p>
        <div className="grid grid-cols-2 gap-3">
          <Dropdown
            placeholder="Highest Volume"
            classRoot="mt-1"
            options={[
              {
                label: "1",
                value: "1"
              },
              {
                label: "2",
                value: "2"
              }
            ]}
            name="strategy_type"
            onChange={value => formik.setFieldValue("strategy_type", value)}
            onBlur={formik.handleBlur}
            value={formik.values.strategy_type}
            error={formik.errors.strategy_type}
            touched={formik.touched.strategy_type}
          />
          <Input
            placeholder="Bid Amount"
            className="mt-0"
            name="strategy_price"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.strategy_price}
            error={formik.errors.strategy_price}
            touched={formik.touched.strategy_price}
          />
        </div>
        <p className="text-sm mt-3 mb-1">Schedule</p>
        <div className="grid grid-cols-2 gap-3">
          <Input
            placeholder="Start Date"
            type="date"
            className="mt-0 pr-0"
            name="start_date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.start_date}
            error={formik.errors.start_date}
            touched={formik.touched.start_date}
          />
          <Input
            placeholder="Start Time"
            type="time"
            className="mt-0 pr-0"
            name="start_time"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.start_time}
            error={formik.errors.start_time}
            touched={formik.touched.start_time}
          />
        </div>
        {showEndData && (
          <div className="grid grid-cols-2 gap-3">
            <Input
              placeholder="End Date"
              type="date"
              className="mt-0 pr-0"
              name="end_date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.end_date}
              error={formik.errors.end_date}
              touched={formik.touched.end_date}
            />
            <Input
              placeholder="End Time"
              type="time"
              className="mt-0 pr-0"
              name="end_time"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.end_time}
              error={formik.errors.end_time}
              touched={formik.touched.end_time}
            />
          </div>
        )}

        <Checkbox
          title="Add End Date"
          onChange={() => setShowEndDate(!showEndData)}
        />
      </div>
      <div className="flex justify-end">
        <Button
          type="submit"
          className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
        >
          Next
        </Button>
      </div>
    </form>
  )
}

export const GoalsTracking = ({ submitData }) => {
  const formik = useFormik({
    initialValues: {
      ad_set_name: "",
      leads_place: "",
      performance_goals: {},
      facebook: {},
      instagram: {},
      attribution: {}
    },
    validationSchema: createCampaignStepThree,
    onSubmit: submitData
  })
  return (
    <form onSubmit={formik.handleSubmit} className="py-5 border rounded-md">
      <div className="border-b-2 px-5 ">
        <p className="mb-2">Goals & Tracking</p>
      </div>
      <div className="mx-5 my-5">
        <Input
          placeholder="Ad Set Name"
          name="ad_set_name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.ad_set_name}
          error={formik.errors.ad_set_name}
          touched={formik.touched.ad_set_name}
        />
        <p className="font-bold my-2">Conversion Location</p>

        <p className="text-sm mb-1">
          Choose where do you want to generate leads
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3">
          <Checkbox
            title="Website"
            name="website"
            onChange={({ target: { name } }) =>
              formik.setFieldValue("leads_place", name)
            }
            onBlur={formik.handleBlur}
            value={formik.values.leads_place}
            error={formik.errors.leads_place}
            touched={formik.touched.leads_place}
          />
          <Checkbox
            title="Instant Form"
            name="instant_form"
            onChange={({ target: { name } }) =>
              formik.setFieldValue("leads_place", name)
            }
            onBlur={formik.handleBlur}
            value={formik.values.leads_place}
            error={formik.errors.leads_place}
            touched={formik.touched.leads_place}
          />
          <Checkbox
            title="Messenger"
            name="messengar"
            onChange={({ target: { name } }) =>
              formik.setFieldValue("leads_place", name)
            }
            onBlur={formik.handleBlur}
            value={formik.values.leads_place}
            error={formik.errors.leads_place}
            touched={formik.touched.leads_place}
          />
          <Checkbox
            title="Instant Form & Messenger"
            name="instant_form_message"
            onChange={({ target: { name } }) =>
              formik.setFieldValue("leads_place", name)
            }
            onBlur={formik.handleBlur}
            value={formik.values.leads_place}
            error={formik.errors.leads_place}
            touched={formik.touched.leads_place}
          />
          <Checkbox
            title="Calls"
            name="calls"
            onChange={({ target: { name } }) =>
              formik.setFieldValue("leads_place", name)
            }
            onBlur={formik.handleBlur}
            value={formik.values.leads_place}
            error={formik.errors.leads_place}
            touched={formik.touched.leads_place}
          />
          <Checkbox
            title="App"
            name="app"
            onChange={({ target: { name } }) =>
              formik.setFieldValue("leads_place", name)
            }
            onBlur={formik.handleBlur}
            value={formik.values.leads_place}
            error={formik.errors.leads_place}
            touched={formik.touched.leads_place}
          />
        </div>
        {formik.errors.leads_place && formik.touched.leads_place && (
          <p className="text text-red-500">{formik.errors.leads_place}</p>
        )}
        <p className="text-sm mt-3 mb-1">Performance Goals</p>
        <Dropdown
          placeholder="-select-"
          classRoot="mt-1"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
          name="performance_goals"
          onChange={value => formik.setFieldValue("performance_goals", value)}
          onBlur={formik.handleBlur}
          value={formik.values.performance_goals}
          error={formik.errors.performance_goals}
          touched={formik.touched.performance_goals}
        />
        <p className="text-sm mt-3 mb-1">Facebook</p>
        <Dropdown
          placeholder="-select-"
          classRoot="mt-1"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
          name="facebook"
          onChange={value => formik.setFieldValue("facebook", value)}
          onBlur={formik.handleBlur}
          value={formik.values.facebook}
          error={formik.errors.facebook}
          touched={formik.touched.facebook}
        />
        <p className="text-sm mt-3 mb-1">Instagram</p>
        <Dropdown
          placeholder="-select-"
          classRoot="mt-1"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
          name="instagram"
          onChange={value => formik.setFieldValue("instagram", value)}
          onBlur={formik.handleBlur}
          value={formik.values.instagram}
          error={formik.errors.instagram}
          touched={formik.touched.instagram}
        />
        <p className="text-sm mt-3 mb-1">Attribution</p>
        <Dropdown
          placeholder="-select-"
          classRoot="mt-1"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
          name="attribution"
          onChange={value => formik.setFieldValue("attribution", value)}
          onBlur={formik.handleBlur}
          value={formik.values.attribution}
          error={formik.errors.attribution}
          touched={formik.touched.attribution}
        />
      </div>
      <div className="flex justify-end">
        <Button
          type="submit"
          className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
        >
          Next
        </Button>
      </div>
    </form>
  )
}

export const AudienceTargeting = ({ submitData }) => {
  const [tabsList, _setTabsList] = useState([
    { title: "Create new audience", value: 1, isActive: true },
    { title: "Use saved audience", value: 2, isActive: false }
  ])

  const setTabsList = activeId => {
    let tempTabs = tabsList.map(item => ({
      ...item,
      isActive: item.value === activeId
    }))
    _setTabsList(tempTabs)
  }
  const formik = useFormik({
    initialValues: {
      locations: "locations",
      state: "",
      city: "",
      zipCode: "",
      age_from: {},
      age_to: {},
      gender: "",
      detailed_targeting: "",
      languages: ""
    },
    validationSchema: createCampaignStepFour,
    onSubmit: submitData
  })
  return (
    <form onSubmit={formik.handleSubmit} className="py-5 border rounded-md">
      <div className="border-b-2 px-5 ">
        <p className="mb-2 font-bold">Audience Targeting</p>
      </div>
      <Tabs tabs={tabsList} handleChange={setTabsList} />
      <div className="mx-5 my-5">
        <p className="text-sm mt-3 mb-1">Custom Audience</p>
        <Dropdown
          placeholder="Search Existing Audience"
          classRoot="mt-1"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
        />
        <Button className="px-7 py-2 bg-[#B6CC43] rounded-xl mr-3">
          Exclude
        </Button>
        <p className="text-sm mt-3 mb-1">Location *</p>
        <Dropdown
          placeholder="People living in or recently in this location"
          classRoot="mt-1"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
        />
        <div className="px-3 py-1 bg-[#F4F4F4] rounded-md">
          <p className="text-sm mt-3 mb-1">United States</p>
          <Input
            placeholder="State"
            className="mt-0 pl-7"
            name="state"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            error={formik.errors.state}
            touched={formik.touched.state}
            iconClasses={"absolute inset-y-0 flex pr-3 cursor-pointer"}
            Icon={() => (
              <div className="pointer-events-none pl-2 inset-y-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  <IconContext.Provider value={{ color: "#B6CC43" }}>
                    <div>
                      <FaMapMarkerAlt />
                    </div>
                  </IconContext.Provider>
                </span>
              </div>
            )}
          />
          <Input
            placeholder="City"
            className="mt-0 pl-7"
            name="city"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            error={formik.errors.city}
            touched={formik.touched.city}
            iconClasses={"absolute inset-y-0 flex pr-3 cursor-pointer"}
            Icon={() => (
              <div className="pointer-events-none pl-2 inset-y-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  <IconContext.Provider value={{ color: "#FF7373" }}>
                    <div>
                      <FaMapMarkerAlt />
                    </div>
                  </IconContext.Provider>
                </span>
              </div>
            )}
          />
          <Input
            placeholder="Zip Code"
            className="mt-0 pl-7"
            name="zipCode"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zipCode}
            error={formik.errors.zipCode}
            touched={formik.touched.zipCode}
            iconClasses={"absolute inset-y-0 flex pr-3 cursor-pointer"}
            Icon={() => (
              <div className="pointer-events-none pl-2 inset-y-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  <IconContext.Provider value={{ color: "#B6CC43" }}>
                    <div>
                      <FaMapMarkerAlt />
                    </div>
                  </IconContext.Provider>
                </span>
              </div>
            )}
          />
        </div>
        {/* <AutoComplete
          apiKey="AIzaSyCMxytqNFtho5-5keI5CVEuvgfvrd5VOPo"
          onPlaceSelected={place => console.log(place)}
          options={{
            types: ["(regions)"],
            componentRestrictions: { country: ["us"] }
          }}
        /> */}

        {/* <GoogleMap /> */}
        <p className="text-sm mt-3 mb-1">Age</p>
        <div className="flex">
          <Dropdown
            placeholder="18"
            classRoot="mt-1 mr-2 w-1/6"
            options={Array.from({ length: 83 }, (_, i) => ({
              value: i + 18,
              label: i + 18
            }))}
            name="age_from"
            onChange={value => formik.setFieldValue("age_from", value)}
            onBlur={formik.handleBlur}
            value={formik.values.age_from}
            error={formik.errors.age_from}
            touched={formik.touched.age_from}
          />
          <p className="text-sm mt-3 mb-1 mr-2">to</p>
          <Dropdown
            placeholder="65"
            classRoot="mt-1 w-1/6"
            options={
              formik.values.age_from
                ? Array.from({ length: 83 }, (_, i) => ({
                    value: i + formik.values.age_from?.value,
                    label: i + formik.values.age_from?.value
                  }))
                : []
            }
            name="age_to"
            onChange={value => formik.setFieldValue("age_to", value)}
            onBlur={formik.handleBlur}
            value={formik.values.age_to}
            error={formik.errors.age_to}
            touched={formik.touched.age_to}
          />
        </div>
        <p className="text-sm mt-3 mb-1">Gender</p>
        <div className="flex items-start mb-1">
          <Checkbox
            title="All"
            name="gender"
            onChange={() => formik.setFieldValue("gender", "All")}
            onBlur={formik.handleBlur}
            value={formik.values.gender}
            error={formik.errors.gender}
            touched={formik.touched.gender}
          />
          <div className="mx-4" />
          <Checkbox
            title="Men"
            name="gender"
            onChange={() => formik.setFieldValue("gender", "Men")}
            onBlur={formik.handleBlur}
            value={formik.values.gender}
            error={formik.errors.gender}
            touched={formik.touched.gender}
          />
          <div className="mx-4" />
          <Checkbox
            title="Women"
            name="gender"
            onChange={() => formik.setFieldValue("gender", "Women")}
            onBlur={formik.handleBlur}
            value={formik.values.gender}
            error={formik.errors.gender}
            touched={formik.touched.gender}
          />
        </div>
        {formik.errors.gender && formik.touched.gender && (
          <p className="text text-red-500">{formik.errors.gender}</p>
        )}
        <p className="text-sm mt-3 mb-1">Detailed Targeting</p>
        <Input
          placeholder="Add demographics, interests, behaviours"
          className="mt-0"
          iconClasses={"absolute inset-y-0 right-0 flex pr-3 cursor-pointer"}
          Icon={() => (
            <div className="flex">
              <div className="pointer-events-none border-l pl-2 border-l-black inset-y-0 right-0 flex items-center pr-3">
                <div className="text-gray-500 sm:text-sm" id="suggestions">
                  Suggestions
                </div>
              </div>
              <div className="border-l pl-2 border-l-black inset-y-0 right-0 flex items-center">
                <button
                  onClick={() => alert("browsed")}
                  className="text-gray-500 sm:text-sm font-medium"
                  id="browse"
                >
                  Browse
                </button>
              </div>
            </div>
          )}
          name="detailed_targeting"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.detailed_targeting}
          error={formik.errors.detailed_targeting}
          touched={formik.touched.detailed_targeting}
        />
        <p className="text-sm mt-3 mb-1">Languages</p>
        <Dropdown
          placeholder="All Languages"
          classRoot="mt-1"
          options={[
            {
              label: "English",
              value: "English"
            },
            {
              label: "German",
              value: "German"
            },
            {
              label: "Urdu",
              value: "Urdu"
            }
          ]}
          name="languages"
          onChange={value => formik.setFieldValue("languages", value)}
          onBlur={formik.handleBlur}
          value={formik.values.languages}
          error={formik.errors.languages}
          touched={formik.touched.languages}
        />
      </div>
      <div className="flex justify-end">
        <Button
          type="submit"
          className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
        >
          Next
        </Button>
      </div>
    </form>
  )
}

export const AdCreative = ({ submitData }) => {
  const formik = useFormik({
    initialValues: {
      ad_setup: "",
      primary_text: "",
      headline: "",
      description: "",
      website: ""
    },
    validationSchema: createCampaignStepFive,
    onSubmit: submitData
  })
  return (
    <form onSubmit={formik.handleSubmit} className="py-5 border rounded-md">
      <div className="border-b-2 px-5 ">
        <p className="mb-2 font-bold">Ad Creative</p>
      </div>
      <div className="mx-5 my-5">
        <p className="text-sm mt-3 mb-1">Ad Setup</p>
        <div className="flex items-start mb-1">
          <Checkbox
            title="Single Image/Video"
            name="ad_setup"
            onChange={() =>
              formik.setFieldValue("ad_setup", "Single_Image/Video")
            }
            onBlur={formik.handleBlur}
            value={formik.values.ad_setup}
            error={formik.errors.ad_setup}
            touched={formik.touched.ad_setup}
          />
          <div className="mx-4" />
          <Checkbox
            title="Carousel"
            name="ad_setup"
            onChange={() => formik.setFieldValue("ad_setup", "Carousel")}
            onBlur={formik.handleBlur}
            value={formik.values.ad_setup}
            error={formik.errors.ad_setup}
            touched={formik.touched.ad_setup}
          />
          <div className="mx-4" />
          <Checkbox
            title="Multi-advertiser ad"
            name="ad_setup"
            onChange={() =>
              formik.setFieldValue("ad_setup", "Multi-advertiser_ad")
            }
            onBlur={formik.handleBlur}
            value={formik.values.ad_setup}
            error={formik.errors.ad_setup}
            touched={formik.touched.ad_setup}
          />
        </div>
        {formik.errors.ad_setup && formik.touched.ad_setup && (
          <p className="text text-red-500">{formik.errors.ad_setup}</p>
        )}
        <Dropdown
          placeholder="Call to Action"
          classRoot="mt-1"
          options={[
            {
              label: "1",
              value: "1"
            },
            {
              label: "2",
              value: "2"
            }
          ]}
          name="call_to_action"
          onChange={value => formik.setFieldValue("call_to_action", value)}
          onBlur={formik.handleBlur}
          value={formik.values.call_to_action}
          error={formik.errors.call_to_action}
          touched={formik.touched.call_to_action}
        />
        <p className="text-sm mt-3 mb-1">Ad Copy</p>
        <div className="flex items-start mb-1">
          <Checkbox
            title="Create New"
            name="ad_copy"
            onChange={() => formik.setFieldValue("ad_copy", "Create_New")}
            onBlur={formik.handleBlur}
            value={formik.values.ad_copy}
            error={formik.errors.ad_copy}
            touched={formik.touched.ad_copy}
          />

          <div className="mx-4" />
          <Checkbox
            title="Choose from Existing"
            name="ad_copy"
            onChange={() =>
              formik.setFieldValue("ad_copy", "Choose_from_Existing")
            }
            onBlur={formik.handleBlur}
            value={formik.values.ad_copy}
            error={formik.errors.ad_copy}
            touched={formik.touched.ad_copy}
          />
        </div>
        <p className="text-sm mt-3 mb-1">Primary Text</p>
        <Input
          placeholder="Enter a Primary Text"
          className="mt-0"
          name="primary_text"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.primary_text}
          error={formik.errors.primary_text}
          touched={formik.touched.primary_text}
        />
        <p className="text-sm mt-3 mb-1">Headline</p>
        <Input
          placeholder="Enter a Headline"
          className="mt-0"
          name="headline"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.headline}
          error={formik.errors.headline}
          touched={formik.touched.headline}
        />
        <p className="text-sm mt-3 mb-1">Description</p>
        <Textarea
          placeholder="Enter a Description"
          className="mt-0"
          name="description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          error={formik.errors.description}
          touched={formik.touched.description}
        />
        <Button className="px-7 py-2 bg-[#B6CC43] rounded-xl mr-3">
          Upload Images
        </Button>
        <Button className="px-7 py-2 bg-[#00B2B4] rounded-xl mr-3">
          Upload Videos
        </Button>
        <p className="text-sm mt-3 mb-1">Website URL</p>
        <Input
          placeholder="Enter a Website URL"
          className="mt-0"
          name="website"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.website}
          error={formik.errors.website}
          touched={formik.touched.website}
        />
      </div>
      <div className="flex justify-end">
        <Button
          type="submit"
          className={`px-7 py-2 bg-[#B6CC43] rounded-xl mr-3`}
        >
          Create
        </Button>
      </div>
    </form>
  )
}
