export default function Textarea({
  label,
  className,
  error,
  touched,
  type = "text",
  placeholder = "",
  ...props
}) {
  return (
    <div>
      {label && (
        <label
          htmlFor="account-number"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <textarea
          rows="4"
          className={`bg-[#F4F4F4] p-2 placeholder:text-black placeholder:opacity-70 placeholder:font-light my-3 block w-full rounded-md border-gray-300 pr-1 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${className}`}
          placeholder={placeholder}
          {...props}
        />
      </div>
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  )
}
