import { useEffect } from "react"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"

import { BaseModal, Dropdown, Input, Button, Select } from "../common"
import { createUserSchema } from "../../helpers/validationSchema"
import { postUsersAPI, updateUsersAPI, updateVerticalsAPI } from "../../service"
import { ORGANIZATION_ROLE_ENUM } from "../../utils/constants"

const dropdownOptions = [
  { label: "Admin", value: "Admin" },
  { label: "Normal", value: "Normal" }
]
export const CreateUserModal = ({ open, setOpen, user }) => {
  const submitHandler = async data => {
    try {
      console.log("data", data, user)
      if (isEmpty(user))
        await postUsersAPI({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: `${data.cc} ${data.phone}`,
          organization_role: data.organization_role.value
        })
      else 
        await updateUsersAPI(data.id, {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: `${data.cc} ${data.phone}`,
          organization_role: data.organization_role.value
        })
      toast.success("User Created")
      formik.resetForm()
      setOpen(false)
    } catch (error) {
      const message =
        (error?.response.data?.non_field_errors &&
          error?.response.data?.non_field_errors[0]) ||
        "something went wrong"
      console.log("error?.response?", error.response)
      console.log("ERROR", error)
      toast.error(message)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      cc: "",
      phone: "",
      organization_role: "",
      ...user
    },
    enableReinitialize: true,
    validationSchema: createUserSchema,
    onSubmit: submitHandler
  })

  useEffect(() => {
    if (user.organization_role) {
      dropdownOptions.map(item => {
        if (item.value === user.organization_role)
          formik.setFieldValue("organization_role", item)
      })
    }
  }, [user])

  return (
    <BaseModal
      open={open}
      setOpen={setOpen}
      title={isEmpty(user) ? "Create User" : "Update User"}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="mx-auto max-w-sm bg-white rounded-lg py-10 px-10 flex flex-col"
      >
        <div className="grid grid-cols-2 gap-2">
          <Input
            placeholder="First Name"
            name="first_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            error={formik.errors.first_name}
            touched={formik.touched.first_name}
          />
          <Input
            placeholder="Last Name"
            name="last_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            error={formik.errors.last_name}
            touched={formik.touched.last_name}
          />
        </div>
        <div className="w-full flex flex-col">
          <Input
            placeholder="E-mail"
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
        </div>
        <div className="col-span-3">
          <Input
            placeholder="Phone"
            type="tel"
            className="w-full"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            error={formik.errors.phone}
            touched={formik.touched.phone}
          />
        </div>
        <div className="w-full flex flex-col">
          {/* <Dropdown
            options={dropdownOptions}
            placeholder="Role"
            onChange={value =>
              formik.setFieldValue("organization_role", { ...value })
            }
            name="organization_role"
            onBlur={formik.handleBlur}
            value={formik.values.organization_role}
          /> */}
          <Select
            placeholder={'Select Role'}
            options={ORGANIZATION_ROLE_ENUM}
            name="organization_role"
            onBlur={formik.handleBlur}
            value={formik.values.organization_role}
            setSelected={item => formik.setFieldValue("organization_role", {...item})}
            selected={formik.values.organization_role}
          />

          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row justify-end">
            <Button
              type="submit"
              className={"bg-[#B6CC43] mr-2 py-2 px-3 shadow-sm"}
            >
              {isEmpty(user) ? "Create" : "Update"}
            </Button>
          </div>
        </div>
      </form>
    </BaseModal>
  )
}

export const UserDetailModal = ({ open, setOpen, user }) => {
  return (
    <BaseModal
      open={open}
      setOpen={setOpen}
      title={"User Details"}
      modalSize="max-w-4xl"
    >
      <div className="mx-auto max-w-4xl bg-white py-10 flex flex-col border rounded-3xl">
        <div className="flex justify-between text-center border-b-2 w-ful px-4 pb-3">
          <div>
            <p>Name</p>
            <p>Wasif Jameel</p>
          </div>
          <div>
            <p>Email</p>
            <p>wasifjameel@cb.com</p>
          </div>
          <div>
            <p>Role</p>
            <p>Admin</p>
          </div>
          <div>
            <p>Organization Name</p>
            <p>RC Organization</p>
          </div>
        </div>
        <div className="px-4 my-4">
          <div className="border rounded-3xl px-4 pt-5">
            <p className="text-lg">Campaign 1</p>
            <div className="flex flex-grow flex-wrap gap-x-5 gap-y-3 my-3">
              <p className="mx-4">ID: #12345</p>
              <p>Revenue: $338</p>
              <p>ROI: 40% </p>
              <p>Budget: $40000</p>
              <p>Amount Spent: $2500</p>
              <p>Profit: $20000</p>
              <p>Assigned Verticals: Vertical 1</p>
              <p>Conversion: 10%</p>
              <p>Impressions: 234</p>
            </div>
          </div>
        </div>
        <div className="px-4 my-4">
          <div className="border rounded-3xl px-4 pt-5">
            <p className="text-lg">Campaign 1</p>
            <div className="flex flex-grow flex-wrap gap-x-5 gap-y-3 my-3">
              <p className="mx-4">ID: #12345</p>
              <p>Revenue: $338</p>
              <p>ROI: 40% </p>
              <p>Budget: $40000</p>
              <p>Amount Spent: $2500</p>
              <p>Profit: $20000</p>
              <p>Assigned Verticals: Vertical 1</p>
              <p>Conversion: 10%</p>
              <p>Impressions: 234</p>
            </div>
          </div>
        </div>
        <div className="px-4 my-4">
          <div className="border rounded-3xl px-4 pt-5">
            <p className="text-lg">Campaign 1</p>
            <div className="flex flex-grow flex-wrap gap-x-5 gap-y-3 my-3">
              <p className="mx-4">ID: #12345</p>
              <p>Revenue: $338</p>
              <p>ROI: 40% </p>
              <p>Budget: $40000</p>
              <p>Amount Spent: $2500</p>
              <p>Profit: $20000</p>
              <p>Assigned Verticals: Vertical 1</p>
              <p>Conversion: 10%</p>
              <p>Impressions: 234</p>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
