import { Switch } from "@headlessui/react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Toggle({ isChecked, onChange, disabled = false }) {
  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <Switch
        checked={isChecked}
        onChange={onChange}
        className={classNames(
          isChecked ? "bg-[#B6CC43]" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
        )}
        disabled={disabled}
      >
        <span
          aria-hidden="true"
          className={classNames(
            isChecked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </Switch.Group>
  )
}
