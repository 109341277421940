export const Automations = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5999 11.12C23.5999 6.32002 21.6799 4.40002 16.8799 4.40002H11.1199C6.3199 4.40002 4.3999 6.32002 4.3999 11.12V16.88C4.3999 21.68 6.3199 23.6 11.1199 23.6"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.22 9.19988C18.56 8.35988 17.456 7.99988 15.8 7.99988H12.2C9.2 7.99988 8 9.19988 8 12.1999V15.7999C8 17.4559 8.36 18.5599 9.188 19.2199"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.21191 4.4V2"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 4.4V2"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8003 4.4V2"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.6001 9.20007H26.0001"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.21191 23.6V26"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.20007H4.4"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 14H4.4"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 18.8H4.4"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6512 21.9079C20.705 21.9079 21.5592 21.0536 21.5592 19.9999C21.5592 18.9462 20.705 18.0919 19.6512 18.0919C18.5974 18.0919 17.7432 18.9462 17.7432 19.9999C17.7432 21.0536 18.5974 21.9079 19.6512 21.9079Z"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2925 20.5521V19.4361C13.2925 18.7761 13.8325 18.2361 14.4925 18.2361C15.6445 18.2361 16.1125 17.4201 15.5365 16.4241C15.2005 15.8481 15.4045 15.1041 15.9805 14.7801L17.0725 14.1441C17.5765 13.8441 18.2245 14.0241 18.5245 14.5281L18.5965 14.6481C19.1725 15.6441 20.1085 15.6441 20.6845 14.6481L20.7565 14.5281C21.0565 14.0241 21.7045 13.8561 22.2085 14.1441L23.3005 14.7801C23.8765 15.1161 24.0805 15.8481 23.7445 16.4241C23.1685 17.4201 23.6365 18.2361 24.7885 18.2361C25.4485 18.2361 25.9885 18.7761 25.9885 19.4361V20.5521C25.9885 21.2121 25.4485 21.7521 24.7885 21.7521C23.6365 21.7521 23.1685 22.5681 23.7445 23.5641C24.0805 24.1401 23.8765 24.8841 23.3005 25.2081L22.2085 25.8441C21.7045 26.1441 21.0565 25.9641 20.7565 25.4601L20.6845 25.3401C20.1085 24.3441 19.1725 24.3441 18.5965 25.3401L18.5245 25.4601C18.2245 25.9641 17.5765 26.1321 17.0725 25.8441L15.9805 25.2081C15.4045 24.8721 15.2005 24.1401 15.5365 23.5641C16.1125 22.5681 15.6445 21.7521 14.4925 21.7521C13.8325 21.7641 13.2925 21.2241 13.2925 20.5521Z"
      stroke="#9B9B9B"
      strokeWidth="2.64959"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Vertical = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 4C7.67 2.75 9.75 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 10.19 2.48 8.49 3.33 7.02L12 12"
      stroke="#9B9B9B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.83002 8.96C6.28378 9.87973 5.99695 10.9303 6.00002 12C6.00002 15.31 8.69002 18 12 18C15.31 18 18 15.31 18 12C18 8.69 15.31 6 12 6C11.09 6 10.22 6.2 9.45002 6.57"
      stroke="#9B9B9B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
