function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Tabs({ tabs, handleChange }) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-[#B6CC43] focus:ring-[#B6CC43]"
          defaultValue={tabs.find(tab => tab.isActive).name}
          onChange={({ target: { value } }) => {
            console.log(value)
          }}
        >
          {tabs.map(tab => (
            <option key={tab.id} value={tab.value}>
              {tab.title}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex justify-evenly" aria-label="Tabs">
            {tabs.map(tab => (
              <button
                type="button"
                onClick={() => handleChange(tab.value)}
                key={tab.title}
                className={classNames(
                  tab.isActive
                    ? "border-[#B6CC43] text-[#B6CC43]"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "border-b-2 py-4 px-1 text-center text-sm font-semibold"
                )}
                aria-current={tab.isActive ? "page" : undefined}
              >
                {tab.title}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
