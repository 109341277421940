// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import common from './common'

const rootReducer = combineReducers({
  common
})

export default rootReducer
