import { BiChevronLeft, BiChevronRight } from "react-icons/bi"

const MAX_PAGE_NUMBERS = 5 // Maximum number of page numbers to display

const Pagination = ({ totalPages, currentPage, setCurrentPage, offset }) => {

  const pageoffset = offset ? offset : 10
  
  const handlePrevious = () => {
    setCurrentPage(currentPage - pageoffset)
  }

  const handleNext = () => {
    setCurrentPage(currentPage + pageoffset)
  }

  const handleClick = page => {
    setCurrentPage((page * pageoffset) - pageoffset)
  }

  const renderPageNumbers = () => {
    let startPage = 1
    let endPage = totalPages
    let currpage = currentPage / offset
    if (totalPages > MAX_PAGE_NUMBERS) {
      const half = Math.floor(MAX_PAGE_NUMBERS / 2)

      if (currpage > half) {
        startPage = currpage - half
        endPage = currpage + half
      } else {
        endPage = MAX_PAGE_NUMBERS
      }

      if (endPage > totalPages) {
        startPage -= endPage - totalPages
        endPage = totalPages
      }
    }

    const pageNumbers = []
   
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          onClick={() => handleClick(i)}
          className={`inline-block mx-1 px-4 py-2 cursor-pointer ${(i === (currpage + 1))
              ? "border-bg bg-[#B6CC43] text-sm font-semibold text-white rounded-full"
              : "bg-gray-200 text-sm font-semibold text-gray-700 rounded-full"
            }`}
        >
          {i}
        </li>
      )
    }

    return pageNumbers
  }

  return (
    <div>
      {(totalPages && totalPages > 0) ? <>
        <ul id="page-numbers" className="my-4 flex items-center">
          {currentPage !== 1 && (
            <li
              onClick={handlePrevious}
              className="inline-block mx-1 cursor-pointer border-bg bg-[#B6CC43] px-2 py-2 text-white rounded-full"
            >
              <BiChevronLeft size={20} />
            </li>
          )}
          {renderPageNumbers()}
          {currentPage !== totalPages && (
            <li
              onClick={handleNext}
              className="inline-block mx-1 cursor-pointer border-bg bg-[#B6CC43] px-2 py-2 text-white rounded-full"
            >
              <BiChevronRight size={20} />
            </li>
          )}
        </ul>
      </> : <></>}
    </div>
  )
}

export default Pagination
